import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';

// import { Button } from '../button/button';
import { ReactComponent as Signet } from '../../../assets/svg/icons/logo.svg';
// import { ReactComponent as Search } from '../../../assets/svg/icons/search.svg';
// import { ReactComponent as Schema } from '../../../assets/svg/icons/schema.svg';
// import { ReactComponent as Queue } from '../../../assets/svg/icons/queue.svg';
// import { ReactComponent as Add } from '../../../assets/svg/icons/add.svg';


import { StyledSidebar } from './sidebar.styled';
// import { StyledSidebarFlexContainer } from './sidebar.styled';


const Sidebar = () => {
  return (
    <StyledSidebar>
      <NavbarBrand tag={Link} to={'/' + window.location.search}><Signet className='h-100' /></NavbarBrand>
      {/* <StyledSidebarFlexContainer>
        <Button
          disabled
          icon={<Add/>}
        />
        <Button
          disabled
          icon={<Search />}
          type="menu"
        />
        <Button
          disabled
          icon={<Schema />}
          type="menu"
        />
        <Button
          disabled
          icon={<Queue />}
          type="menu"
        />
      </StyledSidebarFlexContainer>
      <StyledSidebarFlexContainer>
        <Button
          disabled
          icon={<Search />}
          type="menu"
        />
      </StyledSidebarFlexContainer> */}
    </StyledSidebar>
  );
};

export default Sidebar;
