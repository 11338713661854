import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Right } from '../../../../../assets/svg/icons/right.svg';
import { StyledBreadCrumbs } from './breadCrumbs.styled';

const BreadCrumbs = () => {
  const splitPath = window.location.pathname
    .split("/")
    .filter((x) => x.length > 0)
    .slice(0, -1);

  return (
    <StyledBreadCrumbs>
      <li key={window.location.hostname}>
        <p style={{ color: "var(--primary-500)" }}>
          {window.location.hostname}
        </p>
      </li>
      {splitPath.map((level, index) => {
        return (
          <li key={level + index}>
            <Right className="mx-3" />
            <Link
              to={
                (index > 1 ? "/" : "") +
                splitPath.slice(1, index).join("/") +
                `/${index > 0 ? `${level}/` : ""}` +
                window.location.search
              }
            >
              {level}
            </Link>
          </li>
        );
      })}
    </StyledBreadCrumbs>
  );
};

export default BreadCrumbs;
