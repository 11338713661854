import styled from 'styled-components';

import { theme } from '../../../../styles';


export const StyledNodesList = styled.table`
  width:100%;
  tr,th{
    border-bottom: 1px solid ${theme.colors.grey["50"]};
  }
  thead{
    background: rgba(247, 249, 252, 0.8);
  }
  th{
    height:34px;
    font-size: ${theme.fontSize.small};
    font-weight:500;
    color: ${theme.colors.grey["500"]};
    text-transform:uppercase;
  }
  td,th{
    text-align:left;
  }
  input[type="checkbox"] {
    margin:0 0 0 16px;
    position:relative;
    width: 16px;
    height: 16px;
    vertical-align:text-top;
    color: ${theme.colors.primary["0"]};
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    z-index: 1;
    transition: background 125ms cubic-bezier(0.1, 0.1, 0.15, 1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      left: 4px;
      width: 6px;
      height: 10px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
      border-style: solid;
      border-color: ${theme.colors.primary["0"]};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:checked {
      color: ${theme.colors.primary["0"]};
      background: ${theme.colors.primary["500"]};
      border-color: ${theme.colors.primary["0"]};
      &::before {
        opacity: 1;
      }
    }
  }
  
`;

export const StyledHeader = styled.th`
  width: ${({ size }) => {
    switch (size) {
    case "md":
      return '15%';
    case 'lg':
      return '20%';
    default:
      return '10%';
    };
  }};
`;

export const StyledAttrbCount = styled.span`
  font-size: ${theme.fontSize.small};
  margin-left:6px;
`;

export const StyledFileName = styled.span`
  color: ${theme.colors.primary['500']}
`;

export const StyledNodesListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
`;

export const StyledNodesListItem = styled.tr`
  :hover{
    cursor: pointer;
  }
  height:48px;
  div > span{
    margin-left:1rem;
  }
  svg{
    vertical-align: text-top;
  }
  td:nth-child(1){
    svg{
      margin-left:16px;
    }
  }
`;