import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';

import { StyledNodesListItem, StyledFileName } from './nodesList.styled';
import { ReactComponent as Clock } from '../../../../../assets/svg/icons/clock.svg';
import { icons } from '../../../../../assets/svg/icons/index';
import { Badge } from '../../../../components/badge/badge';
import Delete from '../../../../components/button/delete';
import Attributes from '../attributes/attributes';

export const NodesListItem = ({ attributes, name, type, size, user_content_last_modified, content_type, children, onClick, onKeyDown }) => {

  const contentType = content_type && type !== "directory" 
    ? content_type.split("/")[1].toUpperCase() 
    : type === "directory" ? type : "-";

  const fileSize = () => {
    if (size === 0 ){
      return `0 B`;
    }
    const sizes = [ "B","kB","MB","GB" ];
    const i = Math.floor(Math.log(size) / Math.log(1000));
    return parseFloat((size / Math.pow(1000, i)).toFixed(0)) + ' ' + sizes[i];
  };

  const itemIcon = () => {
    if (type === 'directory') {
      return icons['Directory'];
    }
    else if (icons[contentType] === undefined) {
      return icons["FILE"];
    }
    else {
      return icons[contentType];
    }
  };


  const dateFormat = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h12' };
  const lastModified = user_content_last_modified ? new Date(user_content_last_modified).toLocaleDateString('pl-PL',dateFormat) : '-';
  const tooltipID = uniqueId("content-type-") + name.replaceAll(/[^a-z0-9_]/gi, "_");
  return ( 
    <StyledNodesListItem onClick={onClick} onKeyDown={onKeyDown} tabIndex={0} role="link">
      <td>
        {children}
        {itemIcon()}
      </td> 
      <td>
        <StyledFileName>{ name }</StyledFileName>
      </td>
      <td>
        <Badge id={tooltipID}>{contentType}</Badge>
        <UncontrolledTooltip placementPrefix="content-type-tooltip bs-tooltip" autohide={false} placement="bottom" target={tooltipID}>
          <span>{content_type || type}</span>
        </UncontrolledTooltip>
      </td>
      <td>
        <Badge>{size !== undefined ? fileSize() : "-"}</Badge>
      </td>
      <td>
        <div>
          <Clock />
          <span>{lastModified}</span>
        </div>
      </td>
      <td>
        <Attributes attributes={attributes}/>
      </td>
      <td>
        <Delete name={name}/>
      </td>
    </StyledNodesListItem> 
  );
};

NodesListItem.propTypes = {
  attributes: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  user_content_last_modified: PropTypes.string,
  content_type: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};