import styled from 'styled-components';

import { theme } from '../../../../styles';

export const StyledDropdown = styled.div`
  position: absolute;
  width:100%;
  background: ${theme.colors.grey[0]};

  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 10px 15px -3px rgba(17, 24, 38, 0.1),
    0px 5px 10px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  z-index: 1;
`;

export const StyledButton = styled.button`
  display: flex;
  margin: 0;
  border: 0;
  background: transparent;
  width: 100%;
`;

export const StyledDropdownItem = styled.div`
  color: ${theme.colors.grey[700]};
  font-weight: 500;
`;

export const StyledIcon = styled.div`
  display: flex;
  height: 24px;
  svg {
    widht: 12px;
    height: 12px;
    color: ${theme.colors.primary[400]};
  }
`;
