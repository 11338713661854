import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from '../../../assets/svg/icons/delete.svg';
import { Button } from './button';
import { useIBIS } from '../../hooks/useIbis';
import { useLocation } from 'react-router';

const Delete = ({ name }) =>  {
  const { pathname } = useLocation();
  const IBIS = useIBIS();
  const { t } = useTranslation();
  const [ modal, setModal ] = useState(false);

  const toggleModal = () => setModal(!modal);

  const openModal = (e) => {
    e.stopPropagation();
    setModal(true);
  };

  const submit = () => {
    IBIS.delete({ path: pathname + (name ? name : '') });
    toggleModal();
  };

  return (
    <React.Fragment>
      {modal ?
        <Modal size='sm' isOpen={true} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{t('ibis-ui:delete_confirm')}</ModalHeader>
          <ModalBody>
            <Container>
              <Row className='m-0'>
                <Col>
                  <Button className='w-100' type='secondary' onClick={toggleModal}>{t('ibis-ui:cancel')}</Button>
                </Col>
                <Col>
                  <Button icon={<DeleteIcon/>} className='w-100' onClick={submit}>{t('ibis-ui:delete')}</Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal> :
        null
      }
      {
        name ?
          <Button icon={<DeleteIcon/>} type='secondary' onClick={openModal}/> :
          <Button icon={<DeleteIcon/>} type='secondary' className='ml-2' onClick={openModal} >
            {t('ibis-ui:delete')}
          </Button >
      }
    </React.Fragment>
  );
};

Delete.propTypes = {
  name: PropTypes.string,
};

export default Delete;
