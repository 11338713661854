import { Badge } from '../../../../components/badge/badge';
import { useIBIS } from '../../../../hooks/useIbis';

// import { ReactComponent as Search } from '../../../assets/svg/icons/search.svg';
// import { ReactComponent as Schema } from '../../../assets/svg/icons/schema.svg';
// import { ReactComponent as Queue } from '../../../assets/svg/icons/queue.svg';
// import { ReactComponent as Add } from '../../../assets/svg/icons/add.svg';

const Attributes = () => {
  const IBIS = useIBIS();

  return (
    <>
      {IBIS?.node?.attributes &&
        Object.values(IBIS.node.attributes).map((attribute, index) => (
          <Badge key={`${attribute}${index}`} className={index === 0 ? "" : "ml-3"}>{attribute}</Badge>
        ))}
    </>
  );
};

export default Attributes;
