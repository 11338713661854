import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Col } from 'reactstrap';
import styled from 'styled-components';


import Directory from './pages/nodes/directory/directory';
import File from './pages/nodes/file/file';
import Header from './pages/nodes/header/header';
import Sidebar from './pages/sidebar/sidebar';
import { getDirectoryPath } from './utils/urlHelpers.js';
import { tenantPath } from '../main/utils/authUtils';
import { topbarHeight } from './pages/topbar/topbar.styled';
import { sidebarWidth } from './pages/sidebar/sidebar.styled';

const StyledMainContainer = styled.div`
  display:flex;
  min-height: calc(100vh - ${topbarHeight}px);
`;

const StyledContentContainer = styled(Col)`
  padding: 0;
  max-width: calc(100vw - ${sidebarWidth}px);
  display:flex;
  flex-direction: column;
`;

@withTranslation()
@connect(
  state => ({
    // Kind of fake - used only to refresh content after login and logout
    loginStore: state.login.get('loginData'),
  })
)
export default class Main extends Component {
  render() {
    const pathname = window.location.pathname.replace(tenantPath, '/');
    this.metaPath = '/' + getDirectoryPath(pathname) + '_meta';
    return (
      <Container fluid className='p-0'>
        <StyledMainContainer className='m-0'>
          <Sidebar />
          <StyledContentContainer>
            <Header />
            <div className='pb-3'>
              <Switch>
                {
                  !(pathname.lastIndexOf('/') === (pathname.length - 1)) &&
                  <Route exact
                    path={'/' + getDirectoryPath(pathname) + pathname.slice(pathname.lastIndexOf('/') + 1)}
                    render={() => <File />} />
                }
                <Route path={'/'} component={Directory} />
              </Switch>
            </div>
          </StyledContentContainer>
        </StyledMainContainer>
      </Container>
    );
  }
}

Main.propTypes = {
  t: PropTypes.func, //HOC
  logIn: PropTypes.func,
  entitiesStore: PropTypes.object,
};
