import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StyledNodesGrid } from './nodesGrid.styled';
import { NodesListTile } from './nodesGridTile';
import NodeContainer from '../nodeContainer/nodeContainer';
import { Button } from '../../../../components/button/button';

export const NodesGrid = ({ resources, loadMore }) => {
  const { t } = useTranslation();

  const renderGrid = resources.map((node) => (
    <NodeContainer key={node.name+node.type} type={node.type} name={node.name}>
      <NodesListTile {...node} />
    </NodeContainer>
  ));

  return (
    <div className="p-3">
      <StyledNodesGrid>{renderGrid}</StyledNodesGrid>
      {loadMore && (
        <Button className="mt-3" onClick={loadMore}>
          {t("ibis-ui:load_more")}
        </Button>
      )}
    </div>
  );
};

NodesGrid.propTypes = {
  resources: PropTypes.array,
  loadMore: PropTypes.func,
};

export default NodesGrid;
