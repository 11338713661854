import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '../../../../components/button/button';
import { StyledDropdown, StyledIcon, StyledButton } from './addNode.styled';
import { ReactComponent as Add } from '../../../../../assets/svg/icons/add.svg';
import { ReactComponent as Folder } from '../../../../../assets/svg/icons/folder.svg';
import { ReactComponent as File } from '../../../../../assets/svg/icons/file.svg';

const AddNode = ({ openFileModal, openDirectoryModal, createNewElFromURL }) => {
  const node = useRef();

  const [ dropdown, setDropdown ] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const clickOutside = (e) => {
      if (node.current?.contains(e.target)) {
        return;
      } 
      setTimeout(() => {
        setDropdown(false);
      }, 200);
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  return (
    <div ref={node} className='position-relative ml-2'>
      <Button icon={<Add />} onClick={() => setDropdown(!dropdown)}>
        {createNewElFromURL ? t("ibis-ui:create") : t("ibis-ui:add")}
      </Button>
      {dropdown && (
        <StyledDropdown className="mt-2 p-2">
          <StyledButton onClick={openDirectoryModal} className='p-1'>
            <StyledIcon className="pr-2">
              <Folder className="align-self-center" />
            </StyledIcon>
            {t("ibis-ui:folder")}
          </StyledButton>
          <StyledButton onClick={openFileModal} className='p-1'>
            <StyledIcon className="pr-2">
              <File className="align-self-center" />
            </StyledIcon>
            {t("ibis-ui:file")}
          </StyledButton>
        </StyledDropdown>
      )}
    </div>
  );
};

AddNode.propTypes = {
  openFileModal: PropTypes.func.isRequired,
  openDirectoryModal: PropTypes.func.isRequired,
  createNewElFromURL: PropTypes.bool,
};

export default AddNode;
