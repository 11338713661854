import qs from 'qs';
import { objectExists } from './utils';

export class Client {
  constructor({ tenantUrl, getSecret, axiosInstance }) {
    this.tenantUrl = tenantUrl;
    this.getSecret = getSecret;
    this._axiosInstance = axiosInstance;
  }

  createNode = ({
    node,
    path,
    onUploadProgress = null,
    method = "post",
    contentType,
    signal,
  }) => {
    return this.sendRequest({
      method,
      path,
      data: node,
      onUploadProgress,
      contentType,
      signal,
    });
  };

  deleteNode = ({ path }) => {
    return this.sendRequest({ method: "delete", path: path });
  };

  fetchDirectory = ({ path, query, fetchAll }) => {
    return new Promise((resolve, reject) => {
      this._fetchMore({
        currentResult: [],
        path: path,
        query: query,
        fetchAll: fetchAll,
        resolve: resolve,
        reject: reject,
      });
    });
  };

  fetchFile = async ({
    path,
    responseType,
    detectContentType,
    contentType,
  }) => {
    let finalContentType, finalResponseType;

    if (contentType || detectContentType)
      finalContentType =
        contentType ?? (await this.fetchContentType({ path: path }));

    if (detectContentType) {
      if (
        ![
          "image/svg",
          "image/png",
          "image/jpeg",
          "application/json",
          "text/markdown",
          "text/plain",
        ].includes(finalContentType)
      )
        return Promise.resolve({
          data: {
            content: null,
            contentType: finalContentType,
          },
        });
    }

    if (finalContentType?.substr(0, finalContentType.indexOf("/")) === "image")
      finalResponseType = "blob";

    if (finalContentType?.substr(0, finalContentType.indexOf("/")) === "text")
      finalResponseType = "text";

    finalResponseType = responseType ?? finalResponseType;

    return this.sendRequest({
      method: "get",
      path: path,
      contentType: finalContentType,
      responseType: finalResponseType,
      __silentFor: [ { status: 404 }, { status: 403 } ],
    }).then((response) => {
      return {
        ...response,
        data: {
          content:
            finalResponseType === "text" && response.data instanceof Object
              ? JSON.stringify(response.data, null, 2)
              : response.data,
          contentType: finalContentType ?? response.headers["content-type"],
        },
      };
    });
  };

  fetchContentType = ({ path }) => {
    return this.sendRequest({ method: "head", path: path, __silentFor: [ { status: 404 }, { status: 403 } ] }).then(
      (response) => {
        return response.headers["content-type"];
      }
    );
  };

  _fetchMore = ({
    currentResult = [],
    path,
    query,
    fetchAll,
    resolve,
    reject,
  }) => {
    return this.sendRequest({
      method: "get",
      path,
      query,
      __silentFor: [ { status: 404 }, { status: 403 } ],
    })
      .then((response) => {
        const items = currentResult.concat(response.data.items);
        if (!fetchAll) {
          //Load one page results
          query = response.data.more
            ? qs.parse(response.data.more, { ignoreQueryPrefix: true })
            : null;
          resolve({ ...response, data: { ...response.data, more: query } });
        }
        else {
          //Load all results
          if (response.data.more) {
            query = qs.parse(response.data.more, { ignoreQueryPrefix: true });
            this._fetchMore({
              items,
              path,
              query,
              fetchAll: true,
              resolve,
              reject,
            });
          }
          else {
            resolve({ ...response, data: response.data });
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  };

  sendRequest = ({
    method,
    path,
    contentType,
    data,
    query,
    responseType,
    onUploadProgress,
    __silentFor,
    signal,
  }) => {
    const fullAddress =
      this.tenantUrl +
      (path[0] === "/" ? path.substr(1) : path) +
      (query && Object.keys(query).length > 0 ? "?" + qs.stringify(query) : "");
    const headers = {};

    if (contentType !== undefined) {
      headers["content-type"] = contentType;
    }

    const secret = this.getSecret();
    if (secret) {
      headers["X-ibis-Secret"] = secret;
      headers["Authorization"] = `secret ${secret}`;
    }

    return this._axiosInstance({
      ...(method && { method }),
      ...(fullAddress && { url: fullAddress }),
      ...(data && { data: data }),
      ...(objectExists(headers) && { headers }),
      ...(responseType && { responseType }),
      ...(onUploadProgress && {
        onUploadProgress: onUploadProgress,
      }),
      ...(__silentFor && { __silentFor }),
      __useAuthorizationHeader: "onlyForLoggedIn",
      ...(signal && { signal }),
    });
  };
}
