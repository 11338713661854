import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown';

import { useIBIS } from '../../../hooks/useIbis';
import Loading from '../../../../main/components/loading';

import { StyledText, StyledJson, StyledImage } from './file.styled';

const File = () => {
  const { t } = useTranslation();
  const IBIS = useIBIS();

  const displayFile = () => {
    const dataType = IBIS.node.contentType;
    switch (dataType) {
    case "image/svg":
    case "image/png":
    case "image/jpeg":
      return (
        <StyledImage
          src={URL.createObjectURL(IBIS.node.content)}
          alt={"None"}
        />
      );

    case "application/json":
      return (
        <StyledJson>{JSON.stringify(IBIS.node.content, null, 2)}</StyledJson>
      );

    case "text/markdown":
      return <ReactMarkdown>{IBIS.node.content}</ReactMarkdown>;

    case "text/plain":
      return <StyledText>{IBIS.node.content}</StyledText>;

    default:
      return <h4>{t("ibis-ui:cannot_display")}</h4>;
    }
  };

  if (IBIS.isLoading) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <div>
          <Row className="justify-content-center">
            <Loading />
          </Row>
          <h6>{t("ibis-ui:loading")}</h6>
        </div>
      </Row>
    );
  }

  if (IBIS.error)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>
          {IBIS.error.response?.status === 403
            ? t("ibis-ui:forbidden")
            : IBIS.error.response?.status === 404 
              ? t("ibis-ui:file_not_found") 
              : t("ibis-ui:backend_error")}
        </h6>
      </Row>
    );

  if (!IBIS.node)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{t("ibis-ui:file_not_found")}</h6>
      </Row>
    );

  return (
    <Container fluid className="h-100">
      {displayFile()}
    </Container>
  );
};

export default File;
