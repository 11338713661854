import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/button/button';
import {
  StyledModalBody,
  StyledFileInput,
  StyledModal,
} from './nodeCreator.styled';
import useIBIS from '../../hooks/useIbis';
import FileField from './fileField/fileField';
import UploadProgress from './fileField/uploadProgress';

const FileCreator = ({ modal, closeModal }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS();

  const splitedPathname = pathname.split('/');
  const singleFileName = splitedPathname[splitedPathname.length-1] 
    ? splitedPathname[splitedPathname.length-1] : splitedPathname[splitedPathname.length-2];
  const pathnameForSingleFileCreation = pathname.slice(0, pathname.indexOf(singleFileName)) || "";

  const [ files, setFiles ] = useState([]);
  const [ filesProgress, setFilesProgress ] = useState([]);
  const [ filesCancel, setFilesCancel ] = useState([]);

  const handleFile = (e) => {
    if (e.target.files) {
      if (!IBIS.node) setFiles([ ...e.target.files ]);
      else setFiles((files) => [ ...files, ...e.target.files ]);
    }
  };

  const setProgress = (index, progress) => {
    setFilesProgress((prev) =>
      prev.map((prev, i) => (i === index ? progress : prev))
    );
  };

  const submit = () => {
    if (files.length > 0) {
      setFilesProgress(new Array(files.length).fill(0));
      Promise.allSettled(
        files.map((file, index) => {
          const controller = new AbortController();



          const promise = IBIS.createFile({
            path: !IBIS.node ? pathnameForSingleFileCreation + singleFileName : pathname + file.name,
            file: file,
            signal: controller.signal,
            onUploadProgress: (progressEvent) =>
              setProgress(
                index,
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              ),
          });
          setFilesCancel((prev) => [ ...prev, controller ]);
          return promise;
        })
      ).then(() => {
        close();
      });
    }
  };

  const close = () => {
    setFiles([]);
    setFilesProgress([]);
    setFilesCancel([]);
    closeModal();
  };

  const cancel = () => {
    filesCancel.forEach((controller) => controller.abort());
    close();
  };

  return (
    <StyledModal isOpen={modal} toggle={close}>
      <div className="p-3">
        <h6 className="m-0">{t("ibis-ui:create_file")}</h6>
      </div>
      <StyledModalBody>
        <Container>
          <Row>
            <Col>
              <input
                id="actual-btn"
                hidden
                multiple
                type="file"
                name="file"
                onChange={handleFile}
              />
              <StyledFileInput className="float-right" htmlFor="actual-btn">
                + {t("ibis-ui:add_file")}
              </StyledFileInput>
            </Col>
          </Row>
          <Row>
            <Col className="p-1">
              <label>{t("ibis-ui:selected_files")}:</label>
            </Col>
          </Row>
          {files &&
            files.map((file, index) => {
              const fileName = !IBIS.node ? singleFileName : file.name;

              return filesProgress.length > 0 ? (
                <UploadProgress
                  progress={filesProgress[index]}
                  key={index}
                  name={fileName}
                  cancel={() => filesCancel[index].abort()}
                />
              ) : (
                <FileField
                  createNewElFromURL={!IBIS.node}
                  fileName={fileName}
                  key={index}
                  file={file}
                  index={index}
                  setFiles={setFiles}
                />
              );
            }
            )}
        </Container>
      </StyledModalBody>
      <ModalFooter className="p-3">
        <Row className="m-0 float-right">
          <Button type="secondary" onClick={cancel} className={filesProgress.length === 0 ? "mr-4" : ""}>
            {t("ibis-ui:cancel")}
          </Button>
          {filesProgress.length === 0 && <Button disabled={files.length === 0} onClick={submit}>{t("ibis-ui:upload")}</Button>}
        </Row>
      </ModalFooter>
    </StyledModal>
  );
};

FileCreator.propTypes = {
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default FileCreator;
