import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { StyledAttrbCount } from './attributes.styled';
import { Badge } from '../../../../components/badge/badge';

export const Attributes = ({ attributes }) => {
  if (Object.keys(attributes).length === 0) {
    return <Badge>-</Badge>;
  }
  const badges = Object.values(attributes).map((element, index) => {
    return (
      <Badge key={uniqueId("attr-")} className={index === 0 ? "" : "ml-2"}>
        {element}
      </Badge>
    );
  });

  if (badges.length > 2) {
    return (
      <>
        {badges.slice(0, 2)}
        <Badge className="ml-2">
          <StyledAttrbCount>{`+${badges.length - 2}`}</StyledAttrbCount>
        </Badge>
      </>
    );
  }
  else {
    return <>{badges}</>;
  }
};

Attributes.propTypes = {
  attributes: PropTypes.object,
};

export default Attributes;
