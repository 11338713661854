import { createGlobalStyle } from 'styled-components';

export const theme = {
  colors: {
    primary: {
      900: "#500707",
      800: "#7F0B0B",
      700: "#970D0D",
      600: "#B60F0F",
      500: "#E41313",
      400: "#E94242",
      300: "#EF7171",
      200: "#F4A1A1",
      100: "#F8BFBF",
      50: "#FAD6D6",
      0: "#FDEDED",
    },
    red: {
      600: "#BA1B2E",
      500: "#D1293D",
      300: "#F76F7F",
    },
    grey: {
      900: "#171C26",
      800: "#333B4A",
      700: "#464F60",
      600: "#5A6376",
      500: "#687182",
      400: "#868FA0",
      100: "#D5DBE5",
      50: "#E9EDF5",
      0: "#F7F9FC",
    },
    success: "#00A854",
    danger: "#D20724",
    warning: "#FFA216",
  },
  fontSize: {
    small: "12px",
    medium: "14px",
    large: "16px",
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    font-size: ${theme.fontSize.medium};
    line-height: 20px;
  }

  button {
    font-size: ${theme.fontSize.medium};
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  a {
    color: ${theme.colors.primary[500]};
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: ${theme.colors.primary[500]};
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: ${theme.colors.primary[500]};
  }

  ${Object.keys(theme.colors.primary).map(
    (key) => `
    .primary-${key} {
      fill: ${theme.colors.primary[key]};
    }
  `
  )}

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.87);
  }

  h1, .h1 {
    font-size: 50px;
    line-height: 50px;
  }

  h2, .h2 {
    font-size: 26px;
    line-height: 36px;
  }

  h3, .h3 {
    font-size: 24px;
    line-height: 32px;
  }

  h4, .h4 {
    font-size: 24px;
    line-height: 16px;
  }

  h5, .h5 {
    font-size: 20px;
    line-height: 28px;
  }

  h6, .h6 {
    font-size: 18px;
    line-height: 28px;
  }

  /*** Notifications ***/

  #root div.redux-toastr {
    .toastr {
      border-radius: 8px;
      background-color: ${theme.colors.grey[0]};;
      color: ${theme.colors.grey[600]};;
      padding: 16px 20px;
      font-size: ${theme.fontSize.medium};
      font-weight: 500;
      letter-spacing: 0.02em;
    
      .rrt-middle-container {
        padding: 0;
        margin-left: 30px;
        width: 95%;

        .rrt-title {
          font-size: 16px;
        }
      }
    
      .rrt-left-container {
        width: 40px;
        .rrt-holder {
          width: 40px;
        }
      }
    }

    .without-text {
      min-height: 50px;

      .rrt-left-container {
        height: auto;
        .rrt-holder {
          margin-top: -32px;
          height: auto;
        }
      }

      .rrt-middle-container {
        .rrt-title {
          margin: 0;
        }
      }
    }
  }

  .content-type-tooltip,
  .bs-tooltip {
    
    .tooltip-inner {
      font-size: ${theme.fontSize.small};
      font-weight: 500;
      text-transform: uppercase;
      padding: 9px 12px;
    }
  }
`;
