import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import NodeContainer from '../nodeContainer/nodeContainer';
import { StyledNodesList, StyledHeader } from './nodesList.styled';
import { NodesListItem } from './nodesListItem';
import { Button } from '../../../../components/button/button';

export const NodesList = ({ resources, loadMore }) => {
  const { t } = useTranslation();

  const names = resources.map((node) => {return node.name;});
  const [ checkedFields, setFields ] = useState([]);
  const [ fieldsState, setFieldState ] = useState(names.reduce((name,value) => ({
    ...name, [value]:false
  }),{}));
  
  const handleCheckbox = (e) => {
    let mutableList = [ ...checkedFields ];
    if (e.target.checked) {
      mutableList = [ ...checkedFields, e.target.name ];
      setFieldState({ ...fieldsState, [`${e.target.name}`]: true });
    }
    else {
      mutableList.splice(checkedFields.indexOf(e.target.name), 1);
      setFieldState({ ...fieldsState, [`${e.target.name}`]: false });
    }
    setFields(mutableList);
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      setFields(names);
    }
    else {
      setFields([]);
    }
    setFieldState(
      names.reduce(
        (name, value) => ({
          ...name,
          [value]: e.target.checked,
        }),
        {}
      )
    );
  };

  const renderList = resources.map((node) => {
    return (
      <NodeContainer key={node.name+node.type} type={node.type} name={node.name}>
        <NodesListItem {...node}>
          <Input
            disabled
            type="checkbox"
            checked={fieldsState[`${node.name}`]}
            name={node.name}
            onClick={(e) => {
              e.stopPropagation();
              handleCheckbox(e);
            }}
          />
        </NodesListItem>
      </NodeContainer>
    );
  });

  return (
    <>
      <StyledNodesList>
        <thead>
          <tr>
            <StyledHeader size='sm'>
              <Input disabled type="checkbox" onClick={checkAll}/>
            </StyledHeader>
            <StyledHeader size='lg'>{t('ibis-ui:name')}</StyledHeader>
            <StyledHeader size='md'>{t('ibis-ui:content_type')}</StyledHeader>
            <StyledHeader size='md'>{t('ibis-ui:size')}</StyledHeader>
            <StyledHeader size='lg'>{t('ibis-ui:last_modified')}</StyledHeader>
            <StyledHeader size='md'>{t('ibis-ui:attributes')}</StyledHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {renderList}  
        </tbody> 
      </StyledNodesList> 
      {loadMore && (
        <Button className="mt-3" onClick={loadMore}>
          {t("ibis-ui:load_more")}
        </Button>
      )}
    </>
  );
};

NodesList.propTypes = {
  resources: PropTypes.array,
  loadMore: PropTypes.func,
};
