import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import { useLocation } from 'react-router';

import Loading from '../../../../main/components/loading';
import { NodesGrid } from './nodesGrid/nodesGrid';
import { NodesList } from './nodesList/nodesList';
import { useIBIS } from '../../../hooks/useIbis';
import { Button } from '../../../components/button/button';
import { ReactComponent as Tile } from '../../../../assets/svg/icons/tile.svg';
import { ReactComponent as List } from '../../../../assets/svg/icons/list.svg';

const Directory = () => {
  const [ gridView, setGridView ] = useState(false);
  const { t } = useTranslation();
  const IBIS = useIBIS();

  const { pathname } = useLocation();
  const currentNodeReadMe = useIBIS(false, pathname + "README.md");

  if (
    IBIS.isLoading ||
    (IBIS.node?.items?.find(
      (node) => node.name.toLowerCase() === "readme.md"
    ) &&
      currentNodeReadMe.isLoading)
  ) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <div>
          <Row className="justify-content-center">
            <Loading />
          </Row>
          <h6>{t("ibis-ui:loading")}</h6>
        </div>
      </Row>
    );
  }

  if (IBIS.error)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>
          {IBIS.error.response?.status === 403
            ? t("ibis-ui:forbidden")
            : IBIS.error.response?.status === 404 
              ? t("ibis-ui:directory_not_found") 
              : t("ibis-ui:backend_error")}
        </h6>
      </Row>
    );

  if (!IBIS.node)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{t("ibis-ui:directory_not_found")}</h6>
      </Row>
    );
    
  if (IBIS?.node && IBIS?.node?.items?.length === 0)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{t("ibis-ui:no_resources")}</h6>
      </Row>
    );

  return (
    <>
      <Row className="m-3 mt-4">
        <Button
          onClick={() => setGridView((prev) => !prev)}
          type="secondary"
          className="p-2"
          icon={gridView ? <List /> : <Tile />}
        />
      </Row>
      {gridView ? (
        <NodesGrid resources={IBIS.node.items} loadMore={IBIS.loadMore} />
      ) : (
        <NodesList resources={IBIS.node.items} loadMore={IBIS.loadMore} />
      )}
    </>
  );
};

export default Directory;
