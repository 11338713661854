import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Map } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useLocation } from 'react-router';
import IBIS from '../interface-actions/index';

const initialState = Map({
  isLoading: false,
  error: null,
  node: null,
});

const selectJsState = createSelector(
  (state) => state.IBIS.nodes,
  (_, path) => path,
  (IBIS, path) => {
    return IBIS.get(path) ? IBIS.get(path).toJS() : initialState.toJS();
  }
);

export const useIBIS = (fetchOnInit = false, customPathname = "") => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname: locationPathname } = useLocation();
  const pathname = locationPathname + customPathname;
  const isDirectory = pathname.endsWith('/');
  const state = useSelector((state) => selectJsState(state, pathname));

  const fetchData = useCallback(async () => {
    if (isDirectory)
      dispatch(IBIS.fetchDirectory({ path: pathname, replace: true }));
    else dispatch(IBIS.fetchFile({ path: pathname, detectContentType: true })).then(res => {
      if (res?.headers && res?.headers['ibis-type'] === 'directory')
        history.push(pathname + '/' + window.location.search);
    });
  }, [ dispatch, history, isDirectory, pathname ]);

  const fetchMore = useCallback(async () => {
    dispatch(IBIS.fetchDirectory({ path: pathname, cursor: state.node?.more }));
  }, [ dispatch, pathname, state.node?.more ]);

  useEffect(() => {
    if (state.error?.response?.data?.code === 'INVALID_NODE_TYPE')
      history.push(pathname.slice(0, -1) + window.location.search);
  }, [ state.error, history, pathname ]);

  useEffect(() => {
    if (fetchOnInit) {
      fetchData();
      return () => {
        dispatch(IBIS.clearNode({ path: pathname }));
      };
    };
  }, [ dispatch, fetchData, fetchOnInit, pathname ]);

  return {
    ...state,
    fetchData: fetchData,
    fetchMore: state.node?.more && fetchMore,
    delete: ({ path }) =>
      dispatch(IBIS._delete({ path: path ?? pathname })).then(() =>
        fetchData()
      ),
    createDirectory: ({ attributes, path }) => IBIS.createDirectory({ path: path ?? pathname, attributes }).then(() =>
      fetchData()
    ),
    createFile: ({ file, path, onUploadProgress, signal }) => IBIS.createFile({ path: path ?? pathname, file, onUploadProgress, signal }).then(() =>
      fetchData()
    ),
  };
};

export default useIBIS;
