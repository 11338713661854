import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from './button';
import FileSaver from 'file-saver';

import { ReactComponent as DownloadIcon } from '../../../assets/svg/icons/download.svg';
import { fetchFile } from '../../services';

const Download = (props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const download = () => {
    fetchFile({ path: props.path, responseType: 'blob' }).then(file => {
      FileSaver.saveAs(file.data.content, pathname.split('/').slice(-1)[0]);
    });
  };

  return <Button icon={<DownloadIcon/>} className='ml-2' onClick={download}>{t('ibis-ui:download')}</Button>;
};

export default Download;

Download.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
};