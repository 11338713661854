import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { __env } from '../../../../../envloader';

export const NodeContainer = ({ name, type, children }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const isInFileProviderMode = () => {
    return Boolean(
      qs.parse(window.location.search, { ignoreQueryPrefix: true }).fileProvider
    );
  };

  const notifyHost = () => {
    window.top.postMessage(
      __env.IBIS_TENANT_URL.slice(0, -1) + pathname + name,
      "*"
    );
  };

  const redirect = () => {
    if (type !== "directory" && isInFileProviderMode()) {
      notifyHost();
    }
    else {
      history.push(
        (type === "directory" ? `${name}/` : name) + window.location.search
      );
    }
  };

  const keyDownHandler = (event) => {
    if (event.code === "Enter") {
      redirect();
    }
    else return;
  };

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onClick: redirect,
      onKeyDown: keyDownHandler,
      className: 'm-5',
    });
  }
  );
};

NodeContainer.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.element,
};

export default NodeContainer;
